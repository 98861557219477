<template>
  <CCard>
    <CCardHeader>
      <strong>Resources</strong>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12" align="left">
          <div class="form-group">
            <label class="form__label" style="vertical-align: text-bottom">{{
              $lang.report.filter.date
              }}</label> &nbsp;
            <template>
              <date-range-picker v-model="dateRange"

                                 :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                                 :showWeekNumbers="showWeekNumbers"
                                 :showDropdowns="showDropdowns"
                                 :autoApply="autoApply"
                                 :timePicker="timePicker"
                                 :timePicker24Hour="timePicker24Hour"
              >
                <!--    header slot-->
                <!--    input slot (new slot syntax)-->
                <template #input="picker" style="min-width: 350px;">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <!--    date slot-->
                <template #date="data">
                  <span class="small">{{ data.date | dateCell }}</span>
                </template>
                <!--    ranges (new slot syntax) -->
                <template #ranges="ranges">
                  <div class="ranges">
                    <ul>
                      <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
                        <b>{{ name }}</b> <small class="text-muted">{{ range[0].toDateString() }} -
                        {{ range[1].toDateString() }}</small>
                      </li>
                    </ul>
                  </div>
                </template>
                <!--    footer slot-->
                <div slot="footer" slot-scope="data" class="slot">
                  <div>
                    <b class="text-black">Current Week (m/d/Y)</b> : {{ data.rangeText }}
                  </div>
                  <div style="margin-left: auto">
                    <a @click="data.clickApply" v-if="!data.in_selection" class="btn btn-primary btn-sm">Choose
                      Current</a>
                  </div>
                </div>
              </date-range-picker>
            </template>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
              $lang.filter.job_openings.filter.industry
              }}</label>
            <v-select
                :options="industryOptions"
                v-model="filter.industry"
                @option:selected="getSkills"
            ></v-select>
          </div>
        </CCol>

        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
              $lang.filter.resources.filter.skills
              }}</label>

            <v-select
                multiple="multiple"
                :options="skillsOptions"
                v-model="filter.skills"
            ></v-select>
          </div>
        </CCol>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
              $lang.filter.resources.filter.otherSkills
              }}</label>

            <v-select
                multiple="multiple"
                :options="otherSkillsOptions"
                v-model="filter.otherSkills"
            ></v-select>
          </div>
        </CCol>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
              $lang.report.filter.countries
              }}</label>
            <v-select
                :options="CountryOptions"
                v-model="filter.country"
                @option:selected="loadCities"
            >
            </v-select>
          </div>
        </CCol>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
              $lang.filter.resources.filter.cities
              }}</label>
            <v-select :options="cityOptions" v-model="filter.city">
            </v-select>
          </div>
        </CCol>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label"> {{ $lang.filter.resources.filter.currency }}</label>
            <v-select
                :options="currencyOptions"
                v-model="filter.currency"
            >
            </v-select>
          </div>
        </CCol>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label"
            >{{ $lang.resource.form.experience }}
            </label>
            <v-select
                :options="experienceOptions"
                v-model="filter.experience"
            ></v-select>
          </div>
        </CCol>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{ $lang.filter.resources.filter.remoteWork }}</label>
            <v-select :options="remoteWorkOptions" v-model="filter.remoteWork"></v-select>
          </div>
        </CCol>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{ $lang.filter.resources.filter.canSpeakWithClient }}</label>
            <v-select :options="speakWithClientOptions" v-model="filter.canSpeakWithClient"></v-select>
          </div>
        </CCol>

        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{ $lang.filter.resources.filter.languages }}</label>
            <v-select multiple="multiple" :options="languagesOptions" v-model="filter.languages"></v-select>
          </div>
        </CCol>

        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{ $lang.filter.resources.filter.required }}</label>
            <v-select :options="requiredOptions" v-model="filter.required"></v-select>
          </div>
        </CCol>

        <CCol sm="4">
          <div class="form-group">
            <label class="form__label">{{
              $lang.filter.resources.filter.min_per_hours
              }}</label>
            <VueSimpleRangeSlider
                style="width: 300px"
                :min="0"
                :max="1000000"
                v-model="min"
            />
          </div>
        </CCol>
        <CCol sm="4">
          <div class="form-group">
            <label class="form__label">{{
              $lang.filter.resources.filter.max_per_hours
              }}</label>
            <VueSimpleRangeSlider
                style="width: 300px"
                :min="0"
                :max="1000000"
                v-model="max"
            />
          </div>
        </CCol>
        <CCol sm="4">
          <div class="form-group">
            <label class="form__label">{{
              $lang.filter.resources.filter.min_per_months
              }}</label>
            <VueSimpleRangeSlider
                style="width: 300px"
                :min="0"
                :max="1000000"
                v-model="min_months"
            />
          </div>
        </CCol>
        <CCol sm="4">
          <div class="form-group">
            <label class="form__label">{{
              $lang.filter.resources.filter.max_per_months
              }}</label>
            <VueSimpleRangeSlider
                style="width: 300px"
                :min="0"
                :max="1000000"
                v-model="max_months"
            />
          </div>
        </CCol>
        <CCol sm="3">
          <div class="form-group" style="margin-top: 30px">
            <CButton
                type="submit"
                size="sm"
                color="primary"
                @click="applyFilter()"
                v-c-tooltip="$lang.common.filter.button.filter"
            >
              {{ $lang.common.filter.button.filter }}
            </CButton>
            &nbsp;
            <CButton
                color="danger"
                size="sm"
                @click="resetFilter()"
                v-c-tooltip="$lang.common.filter.button.cancel"
            >
              {{ $lang.common.filter.button.cancel }}
            </CButton>
          </div>
        </CCol>

      </CRow>
      <div>
        <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
        <highcharts class="hc" :options="companyResourcesChartOptions" ref="companyResources"></highcharts>
        <highcharts class="hc" :options="companyResponseChartOptions" ref="companyResponse"></highcharts>
        <highcharts class="hc" :options="companyReportChartOptions" ref="companyReport"></highcharts>
        <highcharts class="hc" :options="industriesChartOptions" ref="industries"></highcharts>
        <highcharts class="hc" :options="citiesChartOptions" ref="cities"></highcharts>
        <highcharts class="hc" :options="skillsChartOptions" ref="skills"></highcharts>
        <highcharts class="hc" :options="otherSkillsChartOptions" ref="other-skills"></highcharts>
        <highcharts class="hc" :options="languagesChart" ref="languagesChart"></highcharts>
        <highcharts class="hc" :options="resourceCurrencyChart" ref="currency"></highcharts>
        <highcharts class="hc" :options="minPerHoursChartOptions" ref="minPerHours"></highcharts>
        <highcharts class="hc" :options="maxPerHoursChartOptions" ref="miaxPerHours"></highcharts>
        <highcharts class="hc" :options="minPerMonthsChartOptions" ref="minPerMonths"></highcharts>
        <highcharts class="hc" :options="maxPerMonthsChartOptions" ref="maxPerMonths"></highcharts>

      </div>
      <CRow>
        <CCol md="6">
          <highcharts class="hc" :options="remoteWorkChartOptions" ref="remoteWork"></highcharts>
        </CCol>
        <CCol md="6">
          <highcharts class="hc" :options="canSpeakWithClient" ref="workType"></highcharts>
        </CCol>
      </CRow>
      <CRow>
        <CCol md="6">
          <highcharts class="hc" :options="experienceChartOptions" ref="experience"></highcharts>
        </CCol>
        <CCol md="6">
          <highcharts class="hc" :options="requiredChartOptions" ref="required"></highcharts>
        </CCol>
      </CRow>

    </CCardBody>
  </CCard>
</template>
<style scoped>
.slot {
  background-color: #3c5de8;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: white;
}
</style>
<script>


import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";

import drilldown from "highcharts/modules/drilldown";

drilldown(Highcharts);
import exporting from "highcharts/modules/exporting";

exporting(Highcharts);
import exportDataHC from "highcharts/modules/export-data";

exportDataHC(Highcharts);

Vue.use(HighchartsVue)

import Vue from "vue";
import {ServerTable} from "vue-tables-2";
import store from "../../store/store";
import {Mixin} from "/src/mixins/index.js";
import VueSimpleRangeSlider from "vue-simple-range-slider";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  country,
  currencytype,
  duration, experience,
  industry,
  opportunity, opportunityTypes,
  priority, reportsOpportunity,
  resourcetype,
  language,
  skills, state,
  users, availability
} from "../../store/url";

Vue.use(ServerTable, {}, false);
let url;
export default {
  name: "ResourceFilter",
  mixins: [Mixin],
  components: {
    VueSimpleRangeSlider,
    DateRangePicker
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 6)
    return {
      self: this,
      chartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Resources, Responses, Reports'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      companyResourcesChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Company Resources '
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      companyResponseChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Company Received Response'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      companyReportChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Company Received Report'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      skillsChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Recent Skills'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      otherSkillsChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Other Skills'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      languagesChart: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Languages'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      resourceCurrencyChart: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Currency'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      industriesChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Industries'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      citiesChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Cities'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      minPerHoursChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Minimum Price Per Hours'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      maxPerHoursChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Maximum Price per Hours'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      minPerMonthsChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Minimum Price Per Months'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      maxPerMonthsChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Maximum Price per Months'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      currencyChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Currencies'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      remoteWorkChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Remote Work'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      requiredChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Availability'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      canSpeakWithClient: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Can Speak With Client'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      experienceChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Experience List'
        },
        subtitle: {
          text: ''
        },
        seriesColor: '#6fcd98',
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      direction: 'ltr',
      format: 'mm/dd/yyyy',
      singleDatePicker: 'range',
      min: [0, 1000000],
      max: [0, 1000000],
      min_months: [0, 1000000],
      max_months: [0, 1000000],
      number: 1000,
      appendToBody: true,
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1,
      showWeekNumbers: true,
      showDropdowns: true,
      autoApply: true,
      timePicker: false,
      timePicker24Hour: false,
      dateRange: {startDate, endDate},
      industryOptions: [],
      services: [],
      durationOptions: [],
      priorityOptions: [],
      skillsOptions: [],
      otherSkillsOptions: [],
      languagesOptions: [],
      CountryOptions: [],
      requiredOptions: [],
      workTypeOptions: [],
      cityOptions: [],
      typesOptions: [],
      currencyOptions: [],
      selectedSkills: [],
      selectedOtherSkills: [],
      selectedLanguages: [],
      selectedWorkType: [],
      userOptions: [],
      remoteWorkOptions: [],
      speakWithClientOptions: [],
      experienceOptions: [],
      module: opportunity,
      moduleIndustry: industry,
      moduleResource: resourcetype,
      moduleDuration: duration,
      moduleCurrency: currencytype,
      modulePriority: priority,
      moduleSkills: skills,
      moduleLanguage: language,
      moduleAvailability: availability,
      countryModule: country,
      stateModule: state,
      moduleUser: users,
      moduleOpportunityTypes: opportunityTypes,
      moduleReportsOpportunity: reportsOpportunity,
      moduleExperience: experience,
      filter: {
        industry: "",
        skills: "",
        otherSkills: "",
        languages: "",
        country: "",
        remoteWork: "",
        experience: "",
        required: "",
        city: "",
        canSpeakWithClient: "",
        currency: {
          label: 'Rs. (India -In )',
          value: '101'
        },
        min_per_hours: "0",
        max_per_hours: "0",
        min_per_months: "0",
        max_per_months: "0"
      },
    };
  },
  filters: {
    dateCell(value) {
      let dt = new Date(value)

      return dt.getDate()
    },
    date(val) {
      return val ? val.toLocaleString() : ''
    }
  },
  created() {
    url = '/reports/resources',
        this.loadIndustry();
    this.countryList();
    this.loadExperience();
    this.loadLanguages();
    this.loadAvailability();

  },
  methods: {
    getSkills() {
      let self = this;
      if (self.filter.industry.value > 0) {
        this.skillsList(self.filter.industry.value);
      }
    },
    loadIndustry() {
      let self = this;
      axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
        response.data.data.map(function (value, key) {
          if (value.industryId === "") {
            self.industryOptions.push({value: value.id, label: value.industry});
          }
        });
      });
    },
    skillsList(industry_id) {
      let self = this;
      const postData = {industry_id: industry_id};
      axios.post(this.listUrlApi(this.moduleSkills), postData).then((response) => {
        self.skillsOptions = [];
        response.data.data.map(function (value, key) {
          self.skillsOptions.push({value: value.id, label: value.title});
          self.otherSkillsOptions.push({value: value.id, label: value.title});
        });
      });
    },
    countryList() {
      let self = this;
      axios.get(this.listUrlApi(this.countryModule)).then((response) => {
        response.data.data.map(function (value, key) {
          self.CountryOptions.push({value: value.id, label: value.name});
          self.currencyOptions.push(
              {
                value: value.id,
                label: value.currencySymbol + ' (' + value.name + '-' + value.countryCode + ' )'
              });
        });
      });
    },
    loadCities() {
      let self = this;
      self.filter.city = '';
      axios
          .get('/cities/country-cities/' + self.filter.country.value)
          .then((response) => {
            self.cityOptions = [];
            response.data.data.map(function (value, key) {
              self.cityOptions.push({value: value.id, label: value.name});
            });
          });
    },
    loadExperience() {
      let self = this;
      axios.get(this.listUrlApi(this.moduleExperience)).then((response) => {
        response.data.data.map(function (value, key) {
          self.experienceOptions.push({value: value.id, label: value.title});
        });
      });
    },
    loadAvailability() {
      let self = this;
      axios.get(this.listUrlApi(this.moduleAvailability)).then((response) => {
        response.data.data.map(function (value, key) {
          self.requiredOptions.push({value: value.id, label: value.title});
        });
      });
    },
    loadLanguages() {
      let self = this;
      axios.get(this.listUrlApi(this.moduleLanguage)).then((response) => {
        response.data.data.map(function (value, key) {
          self.languagesOptions.push({value: value.id, label: value.title});
        });
      });
    },
    loadResources(reqObj) {
      let self = this;
      axios.post(url + '/', reqObj)
          .then((response) => {
            store.commit("showLoader", false);
            self.chartOptions.series = response.data.data;
          });
    },
    registerReport(reqObj) {
      this.loadResources(reqObj);
      this.topCompaniesResources(reqObj);
      this.topReceivedResponse(reqObj);
      this.topReceivedReport(reqObj);
      this.recentSkills(reqObj);
      this.otherSkills(reqObj);
      this.experience(reqObj);
      this.required(reqObj);
      this.remoteWork(reqObj);
      this.speakWithClient(reqObj);
      this.languagesFn(reqObj);
      this.resourceCurrencyFn(reqObj);
      this.industries(reqObj);
      this.minPerHours(reqObj);
      this.maxPerHours(reqObj);
      this.minPerMonths(reqObj);
      this.maxPerMonths(reqObj);
      this.cities(reqObj);
    },
    topCompaniesResources(reqObj) {
      let self = this;
      axios.post(url + '/top-companies-resources', reqObj)
          .then((response) => {
            self.companyResourcesChartOptions.series = response.data.data ?? [];
          });
    },
    topReceivedResponse(reqObj) {
      let self = this;
      axios.post(url + '/top-companies-received-response', reqObj)
          .then((response) => {
            self.companyResponseChartOptions.series = response.data.data ?? [];
          });
    },
    topReceivedReport(reqObj) {
      let self = this;
      axios.post(url + '/top-companies-received-reports', reqObj)
          .then((response) => {
            self.companyReportChartOptions.series = response.data.data ?? [];
          });
    },
    recentSkills(reqObj) {
      let self = this;
      axios.post(url + '/recent-skills', reqObj)
          .then((response) => {
            self.skillsChartOptions.series = response.data.data ?? [];
          });
    },
    otherSkills(reqObj) {
      let self = this;
      axios.post(url + '/other-skills', reqObj)
          .then((response) => {
            self.otherSkillsChartOptions.series = response.data.data ?? [];
          });
    },
    required(reqObj) {
      let self = this;
      axios.post(url + '/availability', reqObj)
          .then((response) => {
            self.requiredChartOptions.series = response.data.data ?? [];
          });
    },
    remoteWork(reqObj) {
      let self = this;
      axios.post(url + '/remote-work', reqObj)
          .then((response) => {
            self.remoteWorkChartOptions.series = response.data.data ?? [];
          });
    },
    speakWithClient(reqObj) {
      let self = this;
      axios.post(url + '/can-speak-with-client', reqObj)
          .then((response) => {
            self.canSpeakWithClient.series = response.data.data ?? [];
          });
    },
    languagesFn(reqObj) {
      let self = this;
      axios.post(url + '/languages', reqObj)
          .then((response) => {
            self.languagesChart.series = response.data.data ?? [];
          });
    },
    resourceCurrencyFn(reqObj) {
      let self = this;
      axios.post(url + '/currency', reqObj)
          .then((response) => {
            self.resourceCurrencyChart.series = response.data.data ?? [];
          });
    },
    industries(reqObj) {
      let self = this;
      axios.post(url + '/top-industries', reqObj)
          .then((response) => {
            self.industriesChartOptions.series = response.data.data ?? [];
          });
    },
    cities(reqObj) {
      let self = this;
      axios.post(url + '/cities', reqObj)
          .then((response) => {
            self.citiesChartOptions.series = response.data.data ?? [];
          });
    },
    experience(reqObj) {
      let self = this;
      axios.post(url + '/experience', reqObj)
          .then((response) => {
            self.experienceChartOptions.series = response.data.data ?? [];
          });
    },
    minPerHours(reqObj) {
      let self = this;
      axios.post(url + '/min-per-hours', reqObj)
          .then((response) => {
            self.minPerHoursChartOptions.series = response.data.data ?? [];
          });
    },
    maxPerHours(reqObj) {
      let self = this;
      axios.post(url + '/max-per-hours', reqObj)
          .then((response) => {
            self.maxPerHoursChartOptions.series = response.data.data ?? [];
          });
    },
    minPerMonths(reqObj) {
      let self = this;
      axios.post(url + '/min-per-months', reqObj)
          .then((response) => {
            self.minPerMonthsChartOptions.series = response.data.data ?? [];
          });
    },
    maxPerMonths(reqObj) {
      let self = this;
      axios.post(url + '/max-per-months', reqObj)
          .then((response) => {
            self.maxPerMonthsChartOptions.series = response.data.data ?? [];
          });
    },
    applyFilter() {
      let self = this;
      let industry = "";
      let currency = "";
      let skills, otherSkills, languages, country, city, remoteWork, required, experience, canSpeakWithClient = "";
      self.selectedWorkType = [];
      self.selectedSkills = [];
      self.selectedLanguages = [];
      self.selectedOtherSkills = [];
      industry = this.checkNullValue(this.filter.industry) ? this.filter.industry.value : '';
      remoteWork = this.checkNullValue(this.filter.remoteWork) ? this.filter.remoteWork.value : '';
      canSpeakWithClient = this.checkNullValue(this.filter.canSpeakWithClient) ? this.filter.canSpeakWithClient.value : '';
      experience = this.checkNullValue(this.filter.experience) ? this.filter.experience.value : '';
      currency = this.checkNullValue(this.filter.currency) ? this.filter.currency.value : '';
      country = this.checkNullValue(this.filter.country) ? this.filter.country.value : '';
      city = this.checkNullValue(this.filter.city) ? this.filter.city.value : '';
      required = this.checkNullValue(this.filter.required) ? this.filter.required.value : '';
      if (this.checkNullValue(this.filter.skills)) {
        skills = self.filter.skills;
        skills.map(function (value, key) {
          self.selectedSkills.push(value.value);
        });
      }
      if (this.checkNullValue(this.filter.otherSkills)) {
        otherSkills = self.filter.otherSkills;
        otherSkills.map(function (value, key) {
          self.selectedOtherSkills.push(value.value);
        });
      }
      if (this.checkNullValue(this.filter.languages)) {
        languages = self.filter.languages;
        languages.map(function (value, key) {
          self.selectedLanguages.push(value.value);
        });
      }
      var reqObj = {
        industry: industry,
        currency: currency,
        dateRange: this.dateRange,
        min: this.min,
        max: this.max,
        min_months: this.min_months,
        max_months: this.max_months,
        country: country,
        city: city,
        required: required,
        skills: self.selectedSkills,
        otherSkills: self.selectedOtherSkills,
        languages: self.selectedLanguages,
        remoteWork: remoteWork,
        canSpeakWithClient: canSpeakWithClient,
        experience: experience,
      };
      store.commit("showLoader", true);
      this.filterReport(reqObj);
    },
    resetFilter() {
      let self = this;
      this.$data.filter = [];
      self.services = [];
      self.skillsOptions = [];
      self.filter.currency = {
        label: 'Rs. (India -In )',
        value: '101'
      }
      this.filterReport();
    },

    filterReport(params) {
      if (!params) {
        let self = this;
        self.currency = this.checkNullValue(this.filter.currency) ? this.filter.currency.value : '';
        var params = {dateRange: this.dateRange, currency: self.currency};
      }
      this.registerReport(params);
      store.commit("showLoader", false);
    }
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    this.filterReport();
    self.remoteWorkOptions.push(
        {value: "Yes", label: "Yes"},
        {value: "No", label: "No"}
    );
    self.speakWithClientOptions.push(
        {value: "Yes", label: "Yes"},
        {value: "No", label: "No"}
    );
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

#container {
  height: 400px;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

</style>
